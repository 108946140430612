.marketing-banner {
  position: fixed;
  z-index: 99999;
  bottom: 0px;
  right: 0px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.marketing-banner.open {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.marketing-banner.closed {
}

.marketing-banner .inner {
}
.marketing-banner.open .inner {
  cursor: default;
  background: #fff;
  /* color: #22223b; */
  padding: 60px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marketing-banner.closed .inner {
  color: white;
  background: #f26a24;
  padding: 20px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.5s;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.marketing-banner.closed .inner:hover {
  transform: scale(1.1);
}

/* media query for phones */
@media (max-width: 600px) {
  .marketing-banner {
    padding: 10px;
  }
  .marketing-banner.open .inner {
    padding: 60px 20px;
  }
}

.marketing-banner.closed .closeIcon {
  display: none;
}

.closeIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}
