/* Any CSS Code we want to add that isnt from webflow can be entered here manually*/
.splide .splide__pagination {
  display: none;
}
.splide__arrows {
  pointer-events: none;
}
.splide__arrows .splide__arrow-embed {
  pointer-events: visible;
}
.splide__item-content {
  pointer-events: none;
}
.splide__item-content .splide__item-title-wrap {
  pointer-events: visible;
}
.splide__slide.is-active .splide__item-img-mask {
  transform: scale(1.25);
}
.splide__slide.is-active .splide__item-img {
  filter: saturate(100%);
}
.splide__slide.is-active .splide__item-title-wrap {
  transition-delay: 0.25s;
  opacity: 1;
  transform: skew(0deg, 0deg) translate(0px, 0em);
}
.splide__slide.is-active .splide__member-position {
  transition-delay: 0.35s;
  opacity: 1;
  transform: skew(0deg, 0deg) translate(0px, 0em);
}
.splide__slide.is-active .splide__item-bio {
  transition-delay: 0.45s;
  opacity: 1;
  transform: translate(0px, 0em);
}

/* slider */
.productSlider {
  width: 50%;
  width: auto;
  /* background-color: red; */
}

.productrow {
  width: 80%;
}
.carousel .slide iframe {
  width: 100% !important;
  margin: 0 !important;
  height: 400px !important;
}

.control-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 160px !important;
}
.sliderIndicator {
  background-color: rgba(34, 34, 34, 0.4);

  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 0px 3px;
  border-radius: 300px;
}
.activeIndicator {
  background-color: black;
}

@media only screen and (max-width: 765px) {
  .control-dots {
    justify-content: center;
    padding-right: 0px !important;
  }
}

.maplogo {
  width: 150px !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.splide__track {
  overflow: visible;
}
.gradient-cover {
  pointer-events: none;
}

.cover {
  background-color: white;
  width: 300px;
  height: 50px;
  margin-top: -50px;
  position: relative;
  z-index: 99999 !important;
}

.processimg {
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
  margin-bottom: 30px;
  max-width: 1100px;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.slide.selected {
  z-index: 0 !important;
}

/* forms */
.w-select {
  background-color: #fff;
}
._w-100 {
  width: 100%;
}
._w-60 {
  width: 59%;
}
._w-40 {
  width: 39%;
}
._w-30 {
  width: 29%;
}
._w-20 {
  width: 19%;
}

.head {
  text-decoration: none;
  transition-duration: 200ms;
  text-align: center;
}
a.head:hover {
  opacity: 0.5;
}
.tablecellcontent {
  text-align: center;
}
.tablecellcontent.small {
  font-size: 12px;
  line-height: 100%;
}
.tablecellcontent p {
  margin: 0;
}

.servicetext.orange {
  color: #f26a24;
}

.herobtns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.navLinkContainer {
  position: relative;
}
.navLinkDropdown {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  min-width: 200px;
}
.navLinkContainer:hover .navLinkDropdown {
  height: auto;
  opacity: 1;
}
.navLinkDropdown .navlink {
  color: black;
  text-shadow: none;
}
.navLinkDropdown .navlink:hover {
  border: none;
}

.navLinkContainer {
  position: relative;
}
.navLinkDropdown {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
}
.navLinkContainer:hover .navLinkDropdown {
  height: auto;
  opacity: 1;
}
.navLinkDropdown .navlink {
  color: black;
  text-shadow: none;
}
.navLinkDropdown .navlink:hover {
  border: none;
}

@media only screen and (max-width: 765px) {
  .navLinkDropdown {
    display: none;
  }
}

.phoneCTACont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}

.phoneCTACont p {
  margin: 0;
}
.phoneCTA {
  color: #f26a24;
  font-weight: bold;
  font-size: 32px;
  margin-top: 10px;
  text-decoration: none;
  transition-duration: 200ms;
}
.phoneCTA:hover {
  opacity: 0.8;
}

.product-cards {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
}
.product-card {
  flex-grow: 1;
  min-width: 200px;
  max-width: 400px;
  margin: 20px;

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition-duration: 200ms;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.product-card:hover {
  opacity: 0.8;
}
.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.cardContent {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
