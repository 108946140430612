.heroCTA {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 3rem;
  text-shadow: 0px 0px 20px var(--black);
}

.heroCTA h1 {
  color: var(--orange);
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.heroCTA h2 {
  color: var(--white);
  font-size: 1.8rem;
}

.heroCTA h4 {
  margin-top: 2.5rem;
  font-size: 1.4rem;
  color: var(--white);
}

.badge {
  width: 200px;
  height: 150px;
  object-fit: contain;
}
.steps h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  line-height: 1.2;
}
/* Media queries for mobile phones */
@media (max-width: 768px) {
  .heroCTA h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }
  .heroCTA h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }
  .heroCTA h4 {
    font-size: 1.2rem;
  }
  .splide__arrows {
    display: none !important;
  }
  .badge {
    width: 150px;
    height: 100px;
  }
  .steps h3 {
    font-size: 1.5rem;
  }
}
